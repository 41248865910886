import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchAssociatedProductDetails } from "./productsAssociatedSlice";
// const BASE_URL = "https://api.dev.siinlab.com"


const isProduction = window.location.hostname === 'platform.siinlab.com';
const BASE_URL = isProduction ? "https://api.siinlab.com/v1" : "https://api.dev.siinlab.com/v1"
// Create an async thunk for fetching all products
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, thunkAPI) => {
    try {
     const response = await axios.get(`${BASE_URL}/product/list`, {
       headers: {
         accept: "application/json",
       },
     });

     const data =  response.data;
     return data;
    } catch (error) {
     toast.error(error)
    }
  }
);


const productSlice = createSlice({
 name: 'products',
 initialState: {
  products:[],
  status: 'idle',
  error: null
 },
 reducers:{},
 extraReducers: (builder)=>{
  builder
  .addCase(fetchProducts.pending, (state)=>{
   state.status = 'Loading'
  })
  .addCase(fetchProducts.fulfilled, (state, action)=>{
   state.status = 'Succeeded';
   state.products = action.payload
  })
  .addCase(fetchProducts.rejected, (state, action)=>{
   state.status = 'Failed'
   state.error = action.error.message
  })
 }
})

export default productSlice.reducer
