import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux/reducers/auth/userSlice";
import React from "react";

const RequireAuth = () => {
  const location = useLocation();
  const token = useSelector(selectCurrentToken)
  return (
    token ? <Outlet/> : <Navigate to="user/login" state={{ from: location }} replace />
  )


};

export default RequireAuth;
