// i18n.js
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import enTranslations from './locales/en.json';
// import frTranslations from './locales/fr.json';
import HttpApi from 'i18next-http-backend';

i18n.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
  // resources: {
  //   en: { translation: enTranslations },
  //   fr: { translation: frTranslations },
  // },
  supportedLngs: ['en', 'fr'],
  fallbackLng: 'en',
  detection:{
    order: ['cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
    caches: ['cookie']
  },
  backend:{
    loadPath: '/locales/{{lng}}/translation.json',
  },
  // react: {useSuspense: false}
});

export default i18n;
