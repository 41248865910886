import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/reducers/auth/userSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthHeader from "./AuthHeader";
import Abstract from "../abstract/Abstract";
import { useState } from "react";
import lockIcon from '../../img/icon_lock.svg'
import atIcon from '../../img/icon_at_.svg'

function Login() {
  const { register, handleSubmit } = useForm();
  const { error, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const toggleShowPassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const onSubmit = async (data) => {
    const result = await dispatch(loginUser(data));
    if (loginUser.fulfilled.match(result)) {
      // Login was successful, navigate to the home page
      navigate("/");
    }
  };

  return (
    <section className="flex sign">
      <section className="sign-form">
        <div className="flex-col">
          <AuthHeader />
          {/* <h1 className="form-title">Login</h1> */}
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email">E-mail:</label>
            <div className="inputs-sign">
            <img src={atIcon} width={"47px"} alt="Email " />
              <input
                type="text"
                id="email"
                placeholder=" acme@example.com"
                {...register("email")}
                required
              />
            </div>
            <label htmlFor="password">Password:</label>
            <div className="inputs-sign flex">
              {/* <i className="fa-solid fa-user-lock"></i> */}
              <img loading="lazy" src={lockIcon} width={"47px"} alt="password icon" />
              <input
              className="input-password"
                type={passwordShown ? "text" : "password"}
                id="password"
                autoComplete="password"
                placeholder=" ********"
                {...register("password")}
                required
              />
              <i className="fa-solid fa-eye" onClick={toggleShowPassword}></i>
            </div>
            <div className="forgot-password">
              <Link href="#">Forgot password ?</Link>
            </div>

            <button className="sign-btn">
              Log in <i className="fa-solid fa-chevron-right"></i>
            </button>
            <p className="flex or-sign">
              {" "}
              <span className="line1"> </span> or{" "}
              <span className="line2"></span>
            </p>
            {/* <a className="seconde-sign" href="/user/register">
              <button className="signup-btn" type="button">
                Sign up
              </button>
            </a> */}
            <NavLink className={'text-dark font-weight-bold text-center'} to={"/user/register"}> <button className="sign-btn signup-btn"> Sign up </button></NavLink>
          </form>
        </div>
      </section>
      <Abstract />
    </section>
  );
}

export default Login;
