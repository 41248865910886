// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .footer {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 5px;
 border-top: 1px solid #384256;
 background-color: var(--main-bg);
 color: white;

}


span:first-child {
 font-weight: bold;
}

span:last-child {
 font-size: 14px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;GAkBG","sourcesContent":["/* .footer {\n display: flex;\n align-items: center;\n justify-content: space-between;\n padding: 5px;\n border-top: 1px solid #384256;\n background-color: var(--main-bg);\n color: white;\n\n}\n\n\nspan:first-child {\n font-weight: bold;\n}\n\nspan:last-child {\n font-size: 14px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
