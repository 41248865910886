import React, { useEffect, useState } from "react";
import "./styles/all_prodcuts.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../redux/reducers/products/productSlice";
import MoonLoader from "react-spinners/BeatLoader";
// import { addAssociated } from "../redux/reducers/products/productSlice";
import { selectCurrentToken } from "../redux/reducers/auth/userSlice";
import imgExmple from "../img/product-icon.svg";
import {
  fetchAssociatedProductDetails,
  fetchAssociatedProductIds,
  selectAssociatedProductIds,
} from "../redux/reducers/products/productsAssociatedSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import customizeIcon from "../img/customize-icon.svg";
import { useTranslation } from "react-i18next";
import SearchIcon from "../components/search-input/SearchIcon";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SEO from "../SEO";
import Tour  from "../components/onboarding/Tour.js";
function AllProducts() {
  // const BASE_URL = "https://api.dev.siinlab.com"
  const isProduction = window.location.hostname === "platform.siinlab.com";
  const BASE_URL = isProduction
    ? "https://api.siinlab.com/v1"
    : "https://api.dev.siinlab.com/v1";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((state) => state.products.products);
  const status = useSelector((state) => state.products.status);
  const token = useSelector(selectCurrentToken);
  const associatedProductIds = useSelector(selectAssociatedProductIds);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  // Filter products based on the search input
  const filteredProducts = products?.filter((product) =>
    product.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const addAssociated = async (productId, token) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/associate/add?product_id=${productId}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Add successfully");
      }
      dispatch(fetchAssociatedProductDetails(token));

      return response.data;
    } catch (error) {
      toast.error(error);
    }
  };
  // Function to add a product as associated
  const handleAddAssociated = async (productId) => {
    // Disable the button for the specific product
    setDisabledButtons((prevDisabled) => [...prevDisabled, productId]);

    try {
      await addAssociated(productId, token);
    } catch (error) {
      toast.error(error);
    }

    // Enable the button for the specific product

    dispatch(fetchAssociatedProductIds(token));
  };



  // check the product is assciated
  const isProductAssociated = (associatedProductIds, productId) => {
    if (token) {
      const isAssociated = associatedProductIds.some(
        (associatedProduct) => associatedProduct.product_id === productId
      );

      return isAssociated;
    } else {
      navigate("/user/login");
    }
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  if (status === "Loading") {
    return (
      <div className="flex moonloader">
        <MoonLoader color="#607D8B" size={20} />
      </div>
    );
  }


  return (
    <>
      <SEO
        title="All Products - Siin Lab"
        description='Explore all products offered by Siin Lab."'
        pageLink="https://platform.siinlab.com/all_products"
        type="website"
        name="platform.siinlab.com/all_products"
        keywords="Moroccan ANPR, Barcode Model, Facial Recognition,"
      />

      {/* <meta name="description" content="A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once."/> */}

      <section className="all-products container">
        {/* <h2 className="">All Products</h2> */}
        {/* Render the Tour component with the updated prop */}
        {/* <Tour  onStepComplete={handleTourStepComplete} currentPage="all_products"/> */}
        {status === "Succeeded" && <Tour currentPage="all_products" />}
        <div className="search-header">
          <h1 className="title">{t("allProducts.title")}</h1>
          <SearchIcon
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        {products &&
          filteredProducts.map((product) => (
            <div className="flex-space-bet" key={product.id}>
              <div className="product">
                <div className="flex">
                  <img src={product.icon} width={"50px"} alt={product.icon} />
                  {/* <img src={imgExmple} width={"50px"} alt="" /> */}
                  <div className="flex title-description">
                    <h3 className="title-description-h3">
                      {/* {t('allProducts.productName')}: */}
                      {product.name}
                    </h3>
                    <p>
                      {/* {t('allProducts.productDescription')}: */}
                      {product.description}{" "}
                    </p>
                  </div>
                </div>
                <p className="cost">Cost/month : ${product.cost} </p>
              </div>
              <div className="flex-col button">
                <button
                  id={"step3"}
                  onClick={() => handleAddAssociated(product.id)}
                  disabled={
                    isProductAssociated(associatedProductIds, product.id) ||
                    disabledButtons.includes(product.id)
                  }
                  className={`enable-btn ${
                    isProductAssociated(associatedProductIds, product.id) ||
                    disabledButtons.includes(product.id)
                      ? "disabled"
                      : ""
                  }`}
                >
                  Activate
                </button>
                <button className="playground-btn ">
                  <a
                    href={product.playground_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark"
                  >
                    Playground
                  </a>
                </button>
              </div>
            </div>
          ))}

      </section>
    </>
  );
}

export default AllProducts;
