import React, { useEffect, useState } from "react";
import JoyRide from "react-joyride";

const TOUR_MANAGE_STEPS = [
  {
    target: "#step9",
    content: "Click the 'Docs' button to learn about using this product. 📚",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#step10",
    content: "Click the 'Generate API' button to create the API. 🛠️",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#step11",
    content: "Copy the generated API by clicking the 'Copy' button. 📋",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#step12",
    content:
      "Deactivate this product by clicking the 'Deactivate' button and entering your password. 🔐",
    placement: "bottom",
    disableBeacon: true,
  },
];
const TOUR_PAYMENT_STEPS = [
  {
    target: "#step13",
    content:  "This represents your global balance. 💰",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#step14",
    content: "Top up your global balance by entering the desired amount.",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#step15",
    content: "After you have entered the desired amount, click on the PayPal icon.",
    placement: "bottom",
    disableBeacon: true,
  },
];

const styles = {
  tooltipContainer: {
    textAlign: "left",
  },
  buttonNext: {
    backgroundColor: "green",
  },
  buttonBack: {
    marginRight: 0,
  },

  buttonClose: {
    // backgroundColor: 'green',
    // borderRadius: '50px',
    // padding:'5px',
    color: "red",
  },
};

function ManageBalanceTour({ currentPage }) {
  const [run, setRun] = useState(false);
  useEffect(() => {
    // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const checkAndStartTour = async () => {
      // Modal Manage Balance
      const modalElementManage = document.querySelector(".manage_balance");
      const isModalBalanceVisible =
        modalElementManage && modalElementManage.classList.contains("show");

      if (
        !localStorage.getItem("isManageBalance") &&
        currentPage === "manage_balance" &&
        isModalBalanceVisible
      ) {
        // await delay(1500);
        setRun(true);
        localStorage.setItem("isManageBalance", true);
      }
      if (
        !localStorage.getItem("isVistePayment") &&
        currentPage === "payment_page"
      ) {
        setRun(true);
        localStorage.setItem("isVistePayment", true);
      }
    };

    setTimeout(() => {
      checkAndStartTour();
    }, 500);
  }, [currentPage]);
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === "finished") {
      // Optionally, you can perform actions when the tour is finished
    }
  };
  const tourSteps =
    currentPage === "manage_balance"
      ? TOUR_MANAGE_STEPS
      : currentPage === "payment_page"
      ? TOUR_PAYMENT_STEPS
      : [];
  return (
    <>
      <JoyRide
        steps={tourSteps}
        styles={styles}
        run={run}
        callback={handleJoyrideCallback}
        hideBackButton
        scrollToFirstStep
        showSkipButton={true}
        showProgress
      />
    </>
  );
}

export default ManageBalanceTour;
