import React from "react";
import Navbar from "../components/Header/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/footer/Fooer";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux/reducers/auth/userSlice";
import Menu from "../components/menu/Menu";
import Tour from "../components/onboarding/Tour";

// const Tour = dynamic(
//   () => import('../components/onboarding/Tour'),
//   { ssr: false }
// )

const RootLayout = ({ hideComponents }) => {
  const token = useSelector(selectCurrentToken);
  const location = useLocation();
  const shouldRenderNavbarAndMenu = location.pathname !== "/user/verify_email" && token ;
  return (
    <>
      {shouldRenderNavbarAndMenu && <Navbar />}
      <div className="flex rootLayout" style={shouldRenderNavbarAndMenu ? { gridTemplateColumns: "70px 1fr" } : { gridTemplateColumns: "1fr" }}>
        {shouldRenderNavbarAndMenu && (
          <div className="menu">
            <Menu />
          </div>
        )}

        <Outlet />
      </div>
    </>
  );
};


export default RootLayout;
