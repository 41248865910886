import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useRef, useState } from "react";
import "./styles/profile.css";
// import { useEffect } from "react";
import {
  fetchUser,
  loading,
  selectCurrentToken,
  selectCurrentUser,
} from "../redux/reducers/auth/userSlice";
import axios from "axios";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/BeatLoader";
import logoBalance from "../img/logo2.png";

import payplChIco from "../img/paypl-chk.png";
import payplVisaIco from "../img/visa-pay.png";
import { useTranslation } from "react-i18next";
import SEO from "../SEO";
import ManageBalanceTour from "../components/onboarding/ManageBalanceTour";

function Payment() {
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const isProduction = window.location.hostname === "platform.siinlab.com";
  const BASE_URL = isProduction
    ? "https://api.siinlab.com/v1"
    : "https://api.dev.siinlab.com/v1";

  let [shouldRenderPayPalButtons, setShouldRenderPayPalButtons] =
    useState(false);
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const [balance, setBalance] = useState("");
  // check if the given balce is number
  const handleBalanceChange = (event) => {
    const enteredBalance = event.target.value;

    // Check if the entered balance is a valid number
    if (isNaN(enteredBalance)) {
      toast.error("Please enter a valid number for the balance.");
      return;
    }

    setBalance(enteredBalance);
  };
  useEffect(() => {
    dispatch(fetchUser(token));
  }, [dispatch, token]);

  const currentUser = useSelector(selectCurrentUser);

  function getValue() {
    var getSelectedValue = document.querySelector('input[name="value"]');
    return getSelectedValue.value;
  }

  function createOrder() {
    let value = Number(balance);
    return fetch(`${BASE_URL}/payments/create-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value), // Stringify the entire payload
    })
      .then((response) => response.json())
      .then((order) => {
        return order.id;
      });
  }

  const myebugger = () => {
    if (balance <= 0) {
      toast.error("The balance should be greater than 0");
      // toast.info
    }
    setShouldRenderPayPalButtons(true);
    // console.log(setShouldRenderPayPalButtons);
    console.log(Number(balance));
  };

  if (paidFor) {
    toast.success("Thank you for you puchase");
  }

  if (error) {
    // display error message on a modal or redirect the user to the error page
    toast.error(error);
  }
  function onApprove(data) {
    return fetch(`${BASE_URL}/payments/orders/capture`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {
        const value =
          orderData.purchase_units[0].payments.captures[0].amount.value;
        //alert(`Transaction completed by ${name}`);
        alert(`Transaction completed by ${value}`);
      });
  }

  const onError = (err) => {
    setError(err);
    toast.error(error);
  };

  const onCancel = () => {
    toast.info(
      "You have cancelled the payment process. If you have any questions or concerns, please contact our support team."
    );
  };

  return (
    <>

      <SEO
      pageLink={'https://platform.dev.siinlab.com/payment'}
     title='Payment Platform-SiinLab'
      description='A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once.'
      type='website'
      name='platform.siinlab.com'
      keywords='Moroccan ANPR, Barcode Model, Facial Recognition,'
     />
    <section className="payment container">
    <ManageBalanceTour currentPage={"payment_page"} />
      <h1 className="title title-payment">{t("profile.title")}</h1>
      <div className="content flex-space-bet">
        <div className="global-balance flex-col">
          <img src={logoBalance} alt="Logo" />
          <p className="text-center">{t("profile.globalBalance")}</p>{" "}
          <p id="step13"> ${currentUser?.balance}</p>
        </div>
        <div className="payment-container flex-space-bet">
          <div className="flex-col" id="step14">
            <p>{t("profile.addFunds")}</p>
            <span>
              {t("profile.amount")}: <br />
              <input
                name="value"
                onChange={handleBalanceChange}
                className="balance-input "
                // type="number"
                placeholder="$ 0.00"
                value={balance}
                type="textt"
              ></input>
              <br />
            </span>
          </div>
          <div className="flex-col">
            <img src={payplVisaIco} width={"150px"} alt="paypeel" />
            <div className="flex">
              <img
              id="step15"
                onClick={myebugger}
                className="payplChIco"
                src={payplChIco}
                width={"120px"}
                alt="paypal"
                // type="button"
                data-toggle="modal"
                data-target="#exampleModal"
              />
            </div>
          </div>
        </div>
        {balance > 0 && (
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header modal-header-payment">
                  <h6
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    {t("profile.addFunds")}
                  </h6>
                  {/* <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  {/* <Checkout/> */}
                  {shouldRenderPayPalButtons && (
                    <PayPalScriptProvider
                      options={{
                        clientId:
                          "AaAEC7vRDS_vkT64M2lB4qm_x0Pf0j4Ls0TG_60hPmz1TJn6no_5M27fjBsXq5mPjVMGMfooCXGiE0OG",
                      }}
                    >
                      <PayPalButtons
                        style={{
                          layout: "vertical",
                          color: "gold",
                          shape: "pill",
                          label: "paypal",
                          height: 35,
                        }}
                        // onClick={(data, actions) => {
                        //   return actions.resolve();
                        // }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        onCancel={onCancel}
                      />
                    </PayPalScriptProvider>
                  )}
                </div>
                <div className="modal-footer flex">
                  <button
                    type="button"
                    className="btn btn-danger px-3"
                    data-dismiss="modal"
                  >
                    {t("profile.closeModal")}
                  </button>
                  {/* <button type="button" className="btn btn-primary">
                  {t('profile.closeModal')}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Reda Code  */}
      {/* <input name="value" type="textt"></input>
      <div id="paypal-button-container"></div>
      <PayPalScriptProvider
        options={{
          clientId:
            "AaAEC7vRDS_vkT64M2lB4qm_x0Pf0j4Ls0TG_60hPmz1TJn6no_5M27fjBsXq5mPjVMGMfooCXGiE0OG",
        }}
      >
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          style={{ layout: "horizontal" }}
        />
      </PayPalScriptProvider>
      <p id="result-message"></p> */}
    </section>
    </>
  );
}

export default Payment;
