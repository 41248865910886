import $ from 'jquery';

const observeBodyChanges = () => {
 const observer = new MutationObserver(mutations => {
   mutations.forEach(mutation => {
     if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
       const body = document.body;
       const modalOpenElement = document.querySelector('.modal-open');

       if (modalOpenElement) {
        console.log('Y*EB');
         $('body').css('padding-right', '0');
       }
     }
   });
 });

 observer.observe(document.body, {
   attributes: true,
   attributeFilter: ['class'],
 });
};

export default observeBodyChanges;


// export default observeBodyChanges;


// export default observeBodyChanges;
