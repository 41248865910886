import React from "react";
import barcodeIcon from "../../img/product-icon.svg";
import vhicleIcon from "../../img/icon=vehicle.svg";
import "./abstract.css";
import { useTranslation } from "react-i18next";
function Abstract() {
  const { t } = useTranslation();
  return (
    // <div className="container">
    <div className="flex-col abstract">
      <h3 className="abstract-title">{t("abstract.title")}</h3>
      <p className="abstract-paragr">{t("abstract.paragraph")}</p>
      <ul className="flex-col abstract-list">
        <li className="flex abstract-item">
          <img src={barcodeIcon} width={"50px"} alt="barcodeIcon" />{" "}
          <div className="flex-col">
            <h6 className="abstrat-item-title">
              {t("abstract.models.barcode.title")}
            </h6>{" "}
            <p>{t("abstract.models.barcode.description")}</p>
          </div>
        </li>
        <li className="flex abstract-item">
          <img src={vhicleIcon} width={"50px"} alt="vhicleIcon" />{" "}
          <div className="flex-col">
            <h6 className="abstrat-item-title">
              {t("abstract.models.vehicle.title")}
            </h6>
            <p>{t("abstract.models.vehicle.description")}</p>
          </div>
        </li>
        <li className="flex abstract-item">
          <img src={barcodeIcon} width={"50px"} alt="barcodeIcon" />{" "}
          <div className="flex-col">
            <h6 className="abstrat-item-title">
              {t("abstract.models.personDetection.title")}
            </h6>
            <p>{t("abstract.models.personDetection.description")}</p>
          </div>
        </li>
      </ul>
      <h6 className="text-center h6-decri">
      {t("abstract.moreModels")}
      </h6>
    </div>
  );
}

export default Abstract;
