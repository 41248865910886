import $ from 'jquery'
const adjustPaddingRight = () => {
 const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
      const modalPopUpElement = document.querySelector('.modal_pop_up');
      if (modalPopUpElement) {
       console.log('YEM')
        $(modalPopUpElement).css('padding-right', '0');
      }
    }
  });
});

observer.observe(document.body, {
  attributes: true,
  attributeFilter: ['class'],
});
};
export default adjustPaddingRight;
