import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  selectCurrentToken,
} from "../../redux/reducers/auth/userSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { fetchAssociatedProductDetails } from "../../redux/reducers/products/productsAssociatedSlice";
import { Link } from "react-router-dom";
import lockIcon from "../../img/icon_lock.svg";
import Tour from "../onboarding/Tour";
import { fetchApiKey } from "../../redux/reducers/products/productsAssociatedSlice";
import ManageBalanceTour from "../onboarding/ManageBalanceTour";
function ManageBalance({ product, deactivationSteps }) {
  // const BASE_URL = "https://api.dev.siinlab.com";
  const isProduction = window.location.hostname === "platform.siinlab.com";
  const BASE_URL = isProduction
    ? "https://api.siinlab.com/v1"
    : "https://api.dev.siinlab.com/v1";

  const associatedProductDetails = useSelector(
    (state) => state.productsAssociated.productDetails
  );
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  const currentProduct = associatedProductDetails.find(
    (prod) => prod.id === product.id
  );

  const [deactivationStep, setDeactivationStep] = deactivationSteps; // Step 1: Display initial content, Step 2: Display deactivation instructions
  const [apiKey, setApiKey] = useState(null);
  const [password, setPassword] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const toggleShowPassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // const handlePassword = (value) => {
  //   setPassword(value);
  // };

  const memoizedProduct = useMemo(() => product, [product]);

  const currentProductMemo = useMemo(
    () =>
      associatedProductDetails.find((prod) => prod.id === memoizedProduct.id),
    [associatedProductDetails, memoizedProduct]
  );

  const disableProduct = async (productId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/associate/remove?product_id=${productId}&user_password=${password}`,
        // {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setPassword("");
        toast.success("This product was successfully removed'");
        setDeactivationStep(1); // Reset to Step 1 after successful deactivation
        dispatch(fetchAssociatedProductDetails(token));
        dispatch(fetchUser(token));
        return true;
      }
    } catch (error) {
      toast.error(error.response.data.detail);
      return false;
    }
  };

  const generateApiKey = async (productId) => {
    try {
      setApiKey(null);
      const response = await axios.post(
        `${BASE_URL}/associate/generate?product_id=${productId}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.api_key) {
        // currentProductMemo.apiKey = response.data.api_key;
        dispatch(fetchApiKey(product.id, { extra: token }));
      } else {
        // setApiKey("please Generate API Key for this project");
        console.log(response);
        // showToast("API Key not available. Please generate one.", "info");
      }

      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.response);

      toast.error(error.response.data.detail);
    }
  };


  useEffect(() => {
    setDeactivationStep(1);
    setApiKey(null);
    // console.log("heey");
    dispatch(fetchApiKey(product.id, { extra: token }));
  }, [product.id, token, dispatch]);
 

  function maskApiKey(apiKey) {

    // Check if apiKey is provided and has a length greater than 8
    if (!apiKey || apiKey.length <= 8) {
        return apiKey; // If not, return the original key
    }



    // Extract the first and last 4 characters of the apiKey
    const firstPart = apiKey.slice(0, 8);
    const lastPart = apiKey.slice(-8);


    // Create the masked string with asterisks
    const maskedString = firstPart + '*'.repeat(6) + lastPart;

    return maskedString;
}
  const handleDeactivateClick = () => {
    setDeactivationStep(2); // Move to Step 2: Display deactivation instructions
  };

  const handleDeactivation = async () => {
    try {
      const success = await disableProduct(currentProduct.id);
      if (success) {
        setPassword("");
        // Close the Bootstrap Modal
        const modalElement = document.getElementById("exampleModalCenter1");
        const bodyElement = document.body;
        const modalBackdropElements =
          document.getElementsByClassName("modal-backdrop");
        if (modalElement) {
          // Remove classes from body
          bodyElement.classList.remove("modal-open");
          modalElement.classList.remove("show");

          console.log("modal open ==>??");
          // Remove classes from each modal backdrop element
          for (let i = 0; i < modalBackdropElements.length; i++) {
            modalBackdropElements[i].classList.remove(
              "modal-backdrop",
              "fade",
              "show"
            );
          }
        }
      }
    } catch (error) {
      toast.error("Error deactivating product. Please try again.");
      console.log(error);
    }
  };

  return (
    <div className="manage_balance_container">
      {/* <!-- Modal --> */}
      <div
        className="manage_balance modal fade "
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
       <ManageBalanceTour currentPage={"manage_balance"} />
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header modalheader">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="close-modal" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>

            {deactivationStep === 1 && (
              <>
                <div className="modal-body flex-col step1 manage-balance-body">
                  <div className="flex product-img">
                    <img src={product && product.icon} width={"50px"} alt="" />

                    <div className="flex-col title-description">
                      <h3 className="title-description-h3">
                        {product && product.name}
                      </h3>
                      <small>Active since {product.active_since}</small>
                    </div>
                  </div>
                  <div className="flex">
                    <p className="p-apikey">
                      API Key:{" "}
                      <small>
                        {currentProductMemo &&
                        currentProductMemo?.apiKey !== null
                          ? maskApiKey(currentProductMemo.apiKey)
                          : apiKey !== null
                          ? apiKey
                          : "Please generate API Key for this project"}
                      </small>
                    </p>
                  </div>
                </div>
              </>
            )}
            {deactivationStep === 2 && (
              <>
                <div className="modal-body flex-col step2 manage-balance-body">
                  <div className="flex-col product-img">
                    <img
                      src={product && currentProductMemo.icon}
                      width={"50px"}
                      alt=""
                    />
                    <div className="flex-col title-description">
                      <h3 className="title-description-h3">
                        {product && currentProductMemo.name}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="flex-col alert alert-warning deactivayions-alert"
                    role="alert"
                  >
                    By deactivating this product:
                    <ul className="flex-col">
                      <li>
                        ✔ Your balance will be automatically transferred to your
                        global one
                      </li>
                      <li>
                        ✔ You will not necessarily get the same API key next
                        time the product is activated
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}

            <div className="modal-footer modal_footer flex">
              {deactivationStep === 1 && (
                <>
                  <Link to={product && product.api_url} target="_blank">
                    <button
                      type="button"
                      id="step9"
                      className="btn btn-secondary"
                    >
                      Docs
                    </button>
                  </Link>

                  <button
                    type="button"
                    id="step10"
                    className="btn btn-secondary"
                    onClick={() => generateApiKey(product.id)}
                  >
                    Generate API Key
                  </button>
                  <button
                    id="step11"
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(currentProductMemo &&
                        currentProductMemo?.apiKey !== null
                          ? currentProductMemo.apiKey: "you didn't generate the Api_Key yet");
                    }}
                  >
                    Copy API key
                  </button>
                  <button
                    type="button"
                    id="step12"
                    className="btn btn-danger"
                    onClick={handleDeactivateClick}
                  >
                    Deactivate
                  </button>
                </>
              )}

              {deactivationStep === 2 && (
                <>
                  <form className="inputs-sign flex">
                    <img src={lockIcon} width={"38px"} alt="password icon" />

                    <input
                      type={passwordShown ? "text" : "password"}
                      // id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password || ""}
                      // autoComplete="password"
                      placeholder="Your Password"
                      className="remover_product_input"
                    />
                    <i
                      className="fa-solid fa-eye"
                      onClick={toggleShowPassword}
                    ></i>
                  </form>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeactivation}
                  >
                    Deactivate
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageBalance;
