import React from "react";
import { NavLink, } from "react-router-dom";
import  activePrImg from '../../img/icon_cube_.svg'
import  allProducImg from '../../img/icon_grid_.svg'
import  credit_card from '../../img/icon_creditcard_.svg'
import LanguagesButton from "../languages/LanguagesButton";


import './menu.css'

function Menu() {
  return (
    <div className="menuContainer">
      <ul>
        <li>
          <NavLink to="/active_products" >
          <img loading="lazy" src={activePrImg}  id={"step4"} alt="Active Products" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/all_products" >
          <img loading="lazy" src={allProducImg} alt="all Products" id={"step2"} />
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/profile" >
          <img loading="lazy" src={credit_card} alt="" id={"step6"} />
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/payment" >
          <img loading="lazy" src={credit_card} alt="payment" id={""} />
          </NavLink>
        </li>
          {/* <li>
            <LanguagesButton/>
          </li> */}

      </ul>
    </div>
  );
}

export default Menu;
