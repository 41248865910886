import './footer.css'
import logo from '../../img/logo-siin.png'
const Footer = () => {
 return (
   <div className="footer">
     <span><img src={logo} width={"90px"} alt="Logo"  /></span>
     <span>© Platform - Siin Lab</span>
   </div>
 )
}

export default Footer
