// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abstract {
 background: linear-gradient(149deg, #1A5382 0%, #E864A3 100%);
 /* height: 100vh; */
 color: #fff;
 /* padding: 50px; */
 gap:10px;
 /* justify-content: space-around; */
 font-size: 12px;
}

.abstract h6 {
 font-size: 15px;
 font-weight: bold;
}

.h6-decri {
 width: 55%
}

.abstrat-item-title {
 margin-bottom: 3px;
}

.abstract-list {
 gap: 15px;
 margin-top: 10px;
 align-items: start;
 width: 55%;
 margin-left: 20px;
 margin-bottom: 10px;
}

.empt-cart {
 margin-top: 60px;
}

.modal.fade.show{
 padding-right: 0 !important;
}

.arrow {
 position: absolute;
 left: 100px;
 top: 9em;
}

.abstract-item {
 gap: 20px;
}

.abstract-item div.flex-col {
 align-items: start;
 justify-content: space-around;
}

.abstract-title {
 color: #FFF;
 width: 65%;
 text-align: center;
 font-family:'Inter', sans-serif;
 font-size: 35px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
}

.abstract-paragr {
 width: 87%;
 color: #FFF;
 font-family: 'Inter', sans-serif;;

 font-size: 20px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 text-align: center;
 padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/abstract/abstract.css"],"names":[],"mappings":"AAAA;CACC,6DAA6D;CAC7D,mBAAmB;CACnB,WAAW;CACX,mBAAmB;CACnB,QAAQ;CACR,mCAAmC;CACnC,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC;AACD;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,SAAS;CACT,gBAAgB;CAChB,kBAAkB;CAClB,UAAU;CACV,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,QAAQ;AACT;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,kBAAkB;CAClB,6BAA6B;AAC9B;;AAEA;CACC,WAAW;CACX,UAAU;CACV,kBAAkB;CAClB,+BAA+B;CAC/B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,UAAU;CACV,WAAW;CACX,gCAAgC;;CAEhC,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;CACnB,kBAAkB;CAClB,aAAa;AACd","sourcesContent":[".abstract {\n background: linear-gradient(149deg, #1A5382 0%, #E864A3 100%);\n /* height: 100vh; */\n color: #fff;\n /* padding: 50px; */\n gap:10px;\n /* justify-content: space-around; */\n font-size: 12px;\n}\n\n.abstract h6 {\n font-size: 15px;\n font-weight: bold;\n}\n\n.h6-decri {\n width: 55%\n}\n\n.abstrat-item-title {\n margin-bottom: 3px;\n}\n\n.abstract-list {\n gap: 15px;\n margin-top: 10px;\n align-items: start;\n width: 55%;\n margin-left: 20px;\n margin-bottom: 10px;\n}\n\n.empt-cart {\n margin-top: 60px;\n}\n\n.modal.fade.show{\n padding-right: 0 !important;\n}\n\n.arrow {\n position: absolute;\n left: 100px;\n top: 9em;\n}\n\n.abstract-item {\n gap: 20px;\n}\n\n.abstract-item div.flex-col {\n align-items: start;\n justify-content: space-around;\n}\n\n.abstract-title {\n color: #FFF;\n width: 65%;\n text-align: center;\n font-family:'Inter', sans-serif;\n font-size: 35px;\n font-style: normal;\n font-weight: 500;\n line-height: normal;\n}\n\n.abstract-paragr {\n width: 87%;\n color: #FFF;\n font-family: 'Inter', sans-serif;;\n\n font-size: 20px;\n font-style: normal;\n font-weight: 400;\n line-height: normal;\n text-align: center;\n padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
