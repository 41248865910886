import React from 'react'
import customizeIcon from '../../img/customize-icon.svg'
import iconSearch from "../../img/icon_search.svg"
function SearchIcon({searchInput,setSearchInput}) {
  return (
    <div className="search-icon flex">
     {/* <img src={customizeIcon} width={"27px"} alt="customize" /> */}
     <div className="inputs-sign inputs-search flex">
     <img src={iconSearch} width={"47px"} className='search-icon' alt="search icon"/>
            <input className=' px-2'  type="search" value={searchInput} onChange={(e)=> setSearchInput(e.target.value)} placeholder="Search"  />
          </div>
    </div>
  )
}

export default SearchIcon

