import { configureStore } from "@reduxjs/toolkit";
import  userReducer from "./reducers/auth/userSlice";
import productReducer from './reducers/products/productSlice'
import productsAssociatedReducer from './reducers/products/productsAssociatedSlice'

const store = configureStore({
 reducer:{
  auth: userReducer,
  products: productReducer,
  productsAssociated: productsAssociatedReducer
 },
 devTools: process.env.NODE_ENV !== 'production'
})

export default store
