import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssociatedProductDetails,
  selectAssociatedProductIds,
  selectProductDetails,
} from "../redux/reducers/products/productsAssociatedSlice";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../redux/reducers/auth/userSlice";
import { useState } from "react";
import MoonLoader from "react-spinners/BeatLoader";
import "./styles/active_products.css";
import { toast } from "react-toastify";

import histogramExmple from "../img/Rectangle 14.png";

import PopupBalance from "../components/popup/PopupBalance";
import ManageBalance from "../components/popup/ManageBalance";
import SearchIcon from "../components/search-input/SearchIcon";
import NoActiveProduct from "../components/no-active-product/NoActiveProduct";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SEO from "../SEO";
import Tour from "../components/onboarding/Tour";

function ActiveProducts() {
  const currentPage =
    window.location.pathname === "/active_products"
      ? "active_products"
      : "home_page";
  const dispatch = useDispatch();
  const associatedProductDetails = useSelector(
    (state) => state.productsAssociated.productDetails
  );
  const token = useSelector(selectCurrentToken);
  const curentUser = useSelector(selectCurrentUser);
  const ProductDetails = useSelector(selectAssociatedProductIds);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showPopupBalance, setShowPopupBalance] = useState(false);
  const [showManageBalance, setShowManageBalance] = useState(false);
  const [deactivationStep, setDeactivationStep] = useState(1);

  const handleBalanceButtonClick = (product) => {
    setSelectedProduct(product);
    setShowPopupBalance(true);
    setShowManageBalance(false);
  };

  const handleManageButtonClick = (product) => {
    setSelectedProduct(product);
    setShowManageBalance(true);
    setShowPopupBalance(false);
    setDeactivationStep(1)

  };

  // Filter products based on the search input
  const filteredProducts = associatedProductDetails.filter((product) =>
    product.name.toLowerCase().includes(searchInput.toLowerCase())
  );
  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchAssociatedProductDetails(token))
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        toast.error("Error fetching data:", error);
      });
  }, [dispatch, token]);
  // const currentPage = window.location.pathname
  if (!dataLoaded) {
    // Display a loading indicator while data is being fetched
    return (
      <div className="flex moonloader">
        <MoonLoader color="#607D8B" size={20} />
      </div>
    );
  }
  return associatedProductDetails.length > 0 ? (
    <>
      <SEO
        pageLink={"https://platform.siinlab.com/active_products"}
        title="Active Products"
        description='A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once."'
        type="website"
        name="platform.siinlab.com"
        keywords="Moroccan ANPR, Barcode Model, Facial Recognition,"
      />
      <section className="active-product container">
        <Tour currentPage={currentPage} />
        <div className="search-header">
          <h1 className="title">Active Products</h1>
          <SearchIcon
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        {associatedProductDetails &&
          filteredProducts.map((product) => (
            <div key={product.id}>
              <div className="flex-space-bet active_product_list">
                <div className="product">
                  <div className="flex product-img">
                    <img
                      loading="lazy"
                      src={product.icon}
                      width={"50px"}
                      alt=""
                    />
                    <div className="flex title-description">
                      <h3 className="title-description-h3">{product.name} </h3>{" "}
                      <small className="active_since">
                        Active since {product.active_since}
                      </small>
                      {/* <small>Active since {formatDate(product.active_since)}</small> */}
                    </div>
                  </div>
                  <div className="flex product-details">
                    {/*<div>
                   <p>stauts: <span className="gray-color"> {product.status} </span> </p>
                  <p>Usage frequency: <span className="gray-color"> 42 c/h </span></p>
                </div>*/}
                    <div>
                      <p className="cost" id={"step5"}>
                        Balance:{" "}
                        <span className="gray-color"> ${product.balance} </span>
                      </p>
                      <p>
                        Cost/month :{" "}
                        <span className="gray-color"> ${product.cost} </span>
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={histogramExmple}
                  alt="diagram"
                  width={"45%"}
                />
                <div className="flex-col button">
                  <button
                    id={"step6"}
                    // onClick={() => handleProductClick(product)}
                    onClick={() => handleBalanceButtonClick(product)}
                    className={`enable-btn  btn-primary`}
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Balance
                  </button>
                  <button
                    className="playground-btn "
                    id="step8"
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenter1"
                    // onClick={() => handleProductClick(product)}
                    onClick={() => handleManageButtonClick(product)}
                  >
                    Manage
                  </button>
                </div>
              </div>
              {showPopupBalance && selectedProduct && (
                <PopupBalance
                  product={selectedProduct}
                  curentUser={curentUser}
                />
              )}{" "}
              {showManageBalance && selectedProduct && (
                <ManageBalance
                  product={selectedProduct}
                  deactivationSteps={[deactivationStep, setDeactivationStep]}
                  // curentUser={curentUser}
                />
              )}{" "}
            </div>
          ))}
      </section>
    </>
  ) : (
    <>
      <Helmet>
        <title>No Active Products </title>
        <meta
          name="description"
          content="A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once."
        />
      </Helmet>
      <NoActiveProduct
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </>
  );
}

export default ActiveProducts;
