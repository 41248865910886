import React from 'react'
import SearchIcon from '../search-input/SearchIcon'
import { Link } from 'react-router-dom'
import emptCart from "../../img/empty-icon.svg";
import arrow from "../../img/arrow.png";
import Tour from '../onboarding/Tour';


function NoActiveProduct({searchInput,setSearchInput}) {
  return (
   <section className="active-product container flex-col no-product">
   <Tour currentPage="home_page" />
   <div className="search-header">
     <h1 className="title">Active Products</h1>
     {/* Search */}
     <SearchIcon searchInput={searchInput} setSearchInput={setSearchInput} />
     {/* <div className="search-icon flex">
       <div className="inputs-sign inputs-search flex">
         <i className="fa-solid fa-magnifying-glass"></i>
         <input type="search"  placeholder="search"  />
       </div>
     </div> */}
   </div>
   <img src={arrow} width={"150px"} className="arrow" alt="" />
   <img
     src={emptCart}
     width={"150px"}
     className="empt-cart"
     alt="empty cart"
     loading="lazy"
   />{" "}
   <h4 className="title">No active products</h4>
   <p className="text-center no-active-product-descr">
     Products will appear here when you activate
     them. <br />Go to the{" "}
     <Link className="to_allproduct_link" to="/all_products">
       All products{" "}
     </Link>
     section and activate products that you intend to use <br />
     Activating a product does not cost money
   </p>
   <button
     className="to_allproduct btn"
     type="button"
     data-toggle="modal"
     data-target="#exampleModalCenter1"
   >
     <Link to={"/all_products"}> All products</Link>
   </button>
 </section>
  )
}

export default NoActiveProduct

