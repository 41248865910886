import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Navbar.css";
import {
  fetchUser,
  logOut,
  selectCurrentToken,
  selectCurrentUser,
} from "../../redux/reducers/auth/userSlice";
import logo from "../../img/logo-siin.png";
import notification from "../../img/icon_belloutline_.svg";
import setting from "../../img/icon_settings2outline_.svg";
import userProfile from "../../img/icon_person_.svg";
import Tour from "../onboarding/Tour";
import { useTranslation } from "react-i18next";
import TourAllProducts from "../onboarding/TourAllProducts";

function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);

  const handleLogout = () => {

       dispatch(logOut());
  };

  useEffect(() => {
    dispatch(fetchUser(token));
  }, [dispatch, token]);


  return (
    token && (
      <header className="header">
        {/* <Tour currentPage="home_page" /> */}
        {/* <TourAllProducts/> */}
        <div className="header__content">
          <NavLink to="/" className="header__content__logo">
            <img src={logo}  alt="Logo" />
          </NavLink>
          <nav
            className={`header__content__nav flex`}
          >
          <div className="header__content__ul-wrapper">
            <ul className={"header__content__ul "} >
              {/* <li className="page">
                <NavLink to="/">
                  <img src={notification} alt="notification" width={"25px"} />
                </NavLink>
              </li>
              <li className="page">
                <NavLink to="/">
                  <img src={setting} alt="setting" width={"25px"} />
                </NavLink>
              </li> */}
              <li className={`page  dropdown  `}>
                <NavLink
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  // onClick={handleLinkClick}#RESPONSIVE
                  // onClick={profileMenuToggleHandler}
                  className={"profile"}
                >
                  <img
                    src={userProfile}
                    alt={userProfile}
                    width={"32px"}
                  />
                </NavLink>
                <div
                  className="dropdown-menu profile-setting"
                  aria-labelledby="dropdownMenuLink"
                >
                  {/* <a className="dropdown-item" href="#">
                    <i className="mr-2 fa-regular fa-user"></i> Account settings{" "}
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="mr-2 fa-regular fa-envelope"></i> Contact us
                  </a> */}
                  <a className="dropdown-item" onClick={handleLogout} href="">
                    <i className="mr-2 fa-solid fa-right-to-bracket"></i> Log
                    out
                  </a>
                </div>
              </li>
              <li className="user_details">
                <span className="username-balance"> {currentUser?.name}</span>
                <span className="user-balance" id="step1">
                  {t("navbar.userBalance")}:{" "}
                  <span className="current_user_balance" >
                    {" "}
                    ${currentUser?.balance}
                  </span>
                </span>
              </li>
            </ul>
          </div>
          </nav>
          {/* <div className="header__content__toggle">
            {!menuOpen ? (
              <BiMenuAltRight onClick={menuToggleHandler} />
            ) : (
              <AiOutlineClose onClick={menuToggleHandler} />
            )}
          </div> */}
        </div>
      </header>
    )
  );
}

export default Navbar;
