import React from 'react'
import notFoundImg from '../img/not_found.svg'

function Missing() {
  return (
    <div className='not_found_page flex-col'>
    <img src={notFoundImg} alt="no- found image"  />

      <h1>404 - Not found</h1>
      <small>The page you requested does not exist</small>
    </div>
  )
}

export default Missing

