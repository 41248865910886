import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = "https://api.dev.siinlab.com/user";

const isProduction = window.location.hostname === 'platform.siinlab.com';
const BASE_URL = isProduction ? "https://api.siinlab.com" : "https://api.dev.siinlab.com"
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (token, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL}/v1/user/details`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      // console.log(error.response.data.detail);
    }
  }
);
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }, thunkAPI) => {
    const formData = new FormData();
    formData.append("grant_type", "");
    formData.append("username", email);
    formData.append("password", password);
    formData.append("scope", "");
    formData.append("client_id", "");
    formData.append("client_secret", "");

    try {
      const response = await axios.post(`${BASE_URL}/login`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const data = response.data;
        // console.log(response);
        localStorage.setItem("token", data.access_token);
        return data;
      } else {
        // console.log(response);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.detail);
      return thunkAPI.rejectWithValue("An error occurred while logging in.");
    }
  }
);

export const generateVerificationCode = createAsyncThunk(
  "user/generateVerificationCode",
  async (tokenSaver) => {
    try {
      const response =await axios.get(`${BASE_URL}/v1/user/generate-code`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${tokenSaver}`,
        },
      });
      if (response.status === 200) {
        toast.success("Verification code generated successfully");
        return response.data;
      } else {
        // If the response status is not 200, treat it as an error
        toast.error(response.data.detail || "Failed to send verification code ")
        // throw new Error(response.data.detail || "Unknown error");
      }
    } catch (error) {
      toast.error(error.data || "Failed to send verification code ")
    }
  }
);

export const signupUser = createAsyncThunk(
  "user/signupUser",
  async ({ fullName, email, password }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/user/register`,
        {
          name: fullName,
          email: email,
          password: password,
        },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status ===200) {
        const data = response.data
          localStorage.setItem("tokenSaver", data.access_token);
        return data;
      }
    } catch (error) {
      toast.error(error.response.data.detail);
    }
  }
);

export const addCompany = createAsyncThunk ("user/addCompany", async({companyName,tokenSaver} )=>{
  try {
    const response = await axios.patch(
      `${BASE_URL}/v1/user/company?company=${companyName}`,
      {},
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${tokenSaver}`,
        },
      }
    );

    // Check the response status and handle accordingly
    if (response.status === 200) {

      return response.data
    } else {

    }
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error as needed
  }
})


// initialize userToken from local storage
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token,
    error: null,
    loading: "idle",
    tokenSaver: null

  },
  reducers: {
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.error = null;
      state.loading = "idle";
      localStorage.removeItem("token");
      localStorage.removeItem("tokenSaver");

    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = "Laoding";
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.access_token;
        // console.log(action.payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        // console.log(action.payload);
        state.error = action.payload; // Set the error message for invalid credentials
      })

      .addCase(signupUser.pending, (state) => {
        state.loading = "Laoding";
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload.user;
        state.tokenSaver = action.payload.access_token;

      })
      .addCase(signupUser.rejected, (state, action) => {

        state.error = action.payload; // Set the error message for invalid credentials
      })
      .addCase(addCompany.pending, (state) => {
        state.loading = "Laoding";
        state.error = null;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload.user;
      })
      .addCase(addCompany.rejected, (state, action) => {
        // console.log(action.payload);
        state.error = action.payload; // Set the error message for invalid credentials
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = "Loading";
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = "idle";
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = "idle";
      })
      .addCase(generateVerificationCode.pending, (state) => {
        state.loading = "Loading";
        state.error = null;
      })
      .addCase(generateVerificationCode.fulfilled, (state, action) => {

        state.loading = "idle";
        toast.success(action.payload)
      })
      .addCase(generateVerificationCode.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload)

        state.loading = "idle";
      });
  },
});

export const { logOut, setToken } = authSlice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentTokenSaver = (state) => state.auth.tokenSaver;
export const loading = (state) => state.auth.loading;
// export const userSelector = (state)=> state.auth
export default authSlice.reducer;
