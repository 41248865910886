import { useState, useEffect, useRef } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/signup.css";
import axios from "../../api/axios";
import { NavLink, Link, redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthHeader from "./AuthHeader";
import Abstract from "../abstract/Abstract";
import barIcon from "../../img/bar.png";
import doneIcon from "../../img/done.svg";
import companyIcon from "../../img/company-icon.png";
import {
  addCompany,
  selectCurrentTokenSaver,
  signupUser,
} from "../../redux/reducers/auth/userSlice";
import { useDispatch, useSelector } from "react-redux";
import lockIcon from "../../img/icon_lock.svg";
import atIcon from "../../img/icon_at_.svg";
import userIcon from "../../img/user(1).svg";

const FULLNAME_REGEX = /^[A-z][A-z0-9- _]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%]).{6,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

function Signup({ setHideComponents }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailRef = useRef();
  const errRef = useRef();

  const [fullName, setFullName] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [companyNameFocus, setCompanyNameFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const toggleShowPassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  // useEffect(() => {
  //   emailRef.current.focus();
  // }, []);

  useEffect(() => {
    const result = FULLNAME_REGEX.test(fullName);
    setValidName(FULLNAME_REGEX.test(fullName));
  }, [fullName]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [fullName, password, matchPassword]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    const validUser = FULLNAME_REGEX.test(fullName);
    const validPassword = PWD_REGEX.test(password);
    const validEmail = EMAIL_REGEX.test(email);

    if (!validUser || !validPassword || !validEmail) {
      toast.error("Invalid Entry");
      return;
    }

    try {
      const response = await dispatch(
        signupUser({ fullName, email, password })
      );
      if (signupUser.fulfilled.match(response)) {
        const data = response.payload;
        setFormStep(2);
        // return <EmailVerification email={email} />;
      }
    } catch (err) {
      toast.error(err.response?.data.detail);
    }
    // if (formStep === 3) {
    //   // Navigate to the EmailVerification component when formStep is 3
    //   // return <EmailVerification email={email} />;
    // }
  };
  const tokenSaver = useSelector(selectCurrentTokenSaver);

  const handleAddCompany = async () => {
    try {
      await dispatch(addCompany({ companyName, tokenSaver }));

      navigate("/user/verify_email");
      // return <EmailVerification email={email} />;
      // }
    } catch (error) {
      toast.error(error);
    }
  };

  const [formStep, setFormStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const handleNextStep = async (e) => {
    e.preventDefault();
    if (formStep === 1 && validEmail && validPassword && validMatch) {
      try {
        await handleSignUp(e);
        // If handleSignUp does not throw an error, proceed to the next step
        // setFormStep(2);
      } catch (error) {
        // If handleSignUp throws an error, stay in the current step
        setFormStep(1);
      }
    } else if (formStep === 2) {
      try {
        await handleAddCompany();
        // If handleAddCompany does not throw an error, proceed to the next step
        setFormStep(3);
      } catch (error) {
        // If handleAddCompany throws an error, stay in the current step
        setFormStep(2);
      }
    }
  };

  return (
    <section className="flex sign">
      {success ? (
        <section>
          <h1 className="form-title">Success</h1>
          <p>
            <a href="#"> Sign In</a>
          </p>
        </section>
      ) : (
        <section className="sign-form">
          <div className="flex-col">
            <AuthHeader />
            {formStep === 1 && (
              <>
                <ul className="header-list flex align-items-start">
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span
                      className={"user_sing mb-1 circle fill flex text-white"}
                    >
                      1
                    </span>
                    <small>Credentials</small>
                  </li>
                  <div className="bar">
                    <img
                      loading="lazy"
                      src={barIcon}
                      alt="bar"
                      width={"130px"}
                      className=""
                    />
                  </div>
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span className={"user_sing mb-1 circle flex"}>2</span>
                    <small>Company information</small>
                  </li>
                  <div className="bar">
                    <img
                      loading="lazy"
                      src={barIcon}
                      alt="campany name"
                      width={"130px"}
                      className=""
                    />
                  </div>
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span className={"user_sing mb-1 circle flex"}>3</span>
                    <small> E-mail verification</small>
                  </li>
                </ul>

                <form className="login-form" onSubmit={handleSignUp}>
                  <label htmlFor="username">Fullname:</label>
                  <div className="inputs-sign">
                    <img
                      // loading="lazy"
                      src={userIcon}
                      width={"45px"}
                      alt="full name"
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validName ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validName || !fullName ? "hide" : "invalid"}
                    />

                    {/* </label> */}
                    <input
                      type="text"
                      id="username"
                      placeholder="Full Name"
                      // ref={fullNameRef}
                      autoComplete="off"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                      required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)}
                    />
                  </div>
                  <label htmlFor="email">E-mail:</label>
                  <div className="inputs-sign">
                    <img
                      // loading="lazy"
                      src={atIcon}
                      width={"47px"}
                      alt="email icon"
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validEmail ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validEmail || !email ? "hide" : "invalid"}
                    />
                    {/* </label> */}
                    <input
                      type="email"
                      placeholder="acme@example.com"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                      aria-invalid={validEmail ? "false" : "true"}
                      aria-describedby="emailnote"
                      ref={emailRef}
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                    />
                  </div>
                  <label htmlFor="password">Password:</label>
                  <div className="inputs-sign flex">
                    {/* <label htmlFor="password">
                Password: */}
                    <img
                      loading="lazy"
                      src={lockIcon}
                      width={"47px"}
                      alt="password icon"
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validPassword ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={
                        validPassword || !password ? "hide" : "invalid"
                      }
                    />
                    {/* </label> */}
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="input-password"
                      placeholder="********"
                      id="password"
                      autoComplete="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                      aria-invalid={validPassword ? "false" : "true"}
                      aria-describedby="pwdnote"
                      onFocus={() => setPasswordFocus(true)}
                      onBlur={() => setPasswordFocus(false)}
                    />
                    <i
                      className="fa-solid fa-eye"
                      onClick={toggleShowPassword}
                    ></i>
                  </div>
                  <label htmlFor="confirm_pwd">Confirm password:</label>
                  <div className="inputs-sign flex">
                    <img
                      loading="lazy"
                      src={lockIcon}
                      width={"47px"}
                      alt="confirm password "
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validMatch && matchPassword ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={
                        validMatch || !matchPassword ? "hide" : "invalid"
                      }
                    />

                    <input
                      type={passwordShown ? "text" : "password"}
                      className="input-password"
                      placeholder="********"
                      autoComplete="confirm_password"
                      id="confirm_pwd"
                      onChange={(e) => setMatchPassword(e.target.value)}
                      value={matchPassword}
                      required
                      aria-invalid={validMatch ? "false" : "true"}
                      aria-describedby="confirmnote"
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                    />
                    <i
                      className="fa-solid fa-eye"
                      onClick={toggleShowPassword}
                    ></i>
                  </div>
                  <button
                    className="sign-btn text-center"
                    disabled={
                      !validName || !validPassword || !validMatch ? true : false
                    }
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                  <p className="flex or-sign">
                    {" "}
                    <span className="line1"> </span> or{" "}
                    <span className="line2"></span>
                  </p>

                  <NavLink
                    className={"sign-btn signup-btn text-center"}
                    to={"/user/login"}
                  >
                    Back to login
                  </NavLink>
                </form>
              </>
            )}
            {formStep === 2 && (
              <>
                <ul className="header-list flex align-items-start">
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span
                      className={"user_sing mb-1 circle fill flex bg-success"}
                    >
                      {" "}
                      <img
                        loading="lazy"
                        src={doneIcon}
                        width={"15px"}
                        alt="doneIcon"
                      />{" "}
                    </span>
                    <small onClick={() => setFormStep(1)}>Credentials</small>
                  </li>
                  <div className="bar">
                    {/* <img loading="lazy" src={barIcon} alt="bar" width={"130px"} className="" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="130px"
                      height="7"
                      viewBox="0 0 404 7"
                      fill="none"
                    >
                      <path
                        d="M0.464844 3.5C0.464844 1.567 2.03185 0 3.96484 0H399.965C401.898 0 403.465 1.567 403.465 3.5C403.465 5.433 401.898 7 399.965 7H3.96485C2.03185 7 0.464844 5.433 0.464844 3.5Z"
                        fill="url(#paint0_linear_315_593)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_315_593"
                          x1="43.1523"
                          y1="3.99988"
                          x2="202.152"
                          y2="3.99988"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#499173" />
                          <stop offset="0.940366" stopColor="#1A5382" />
                          {/* <stop offset="1" stopColor="#BBBBBB"/> */}
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span className={"user_sing mb-1 circle flex text-white"}>
                      2
                    </span>
                    <small>Company information</small>
                  </li>
                  <div className="bar">
                    <img src={barIcon} alt="bar" width={"130px"} className="" />
                  </div>
                  <li className="header-list-item mx-2 flex-col text-center">
                    <span className={"user_sing mb-1 circle flex"}>3</span>
                    <small> E-mail verification</small>
                  </li>
                </ul>

                <form className="login-form">
                  <label htmlFor="email">Company name:</label>
                  <div className="inputs-sign">
                    <img
                      src={companyIcon}
                      width={"40px"}
                      className="fa-solid"
                      alt="company name"
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={companyName ? "valid" : "hide"}
                    />

                    <input
                      type="text"
                      placeholder="ACME inc."
                      id="company-name"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                      required
                      // aria-invalid={validEmail ? "false" : "true"}
                      // aria-describedby="emailnote"
                      // ref={emailRef}
                      onFocus={() => setCompanyNameFocus(true)}
                      onBlur={() => setCompanyNameFocus(false)}
                    />
                  </div>

                  <button
                    className="sign-btn text-center"
                    disabled={
                      !validName ||
                      !validPassword ||
                      !validMatch ||
                      companyName === ""
                        ? true
                        : false
                    }
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                  <p className="flex or-sign">
                    {" "}
                    <span className="line1"> </span> or{" "}
                    <span className="line2"></span>
                  </p>

                  <NavLink
                    className={"sign-btn signup-btn text-center"}
                    to={"/user/login"}
                  >
                    Back to login
                  </NavLink>
                </form>
              </>
            )}
          </div>
        </section>
      )}
      <Abstract />
    </section>
  );
}

export default Signup;
