// productsAssociated.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// const token = useSelector(selectCurrentToken)
// const BASE_URL = "https://api.dev.siinlab.com"

const isProduction = window.location.hostname === 'platform.siinlab.com';
const BASE_URL = isProduction ? "https://api.siinlab.com/v1" : "https://api.dev.siinlab.com/v1"
// Step 1: Define an async thunk to fetch the list of associated product IDs
export const fetchAssociatedProductIds = createAsyncThunk(
  "productsAssociated/fetchAssociatedProductIds",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/associate/list`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("fetchAssociatedProductIds==>", response.data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error.response.data);
    }
  }
);

// Step 2: Define an async thunk to fetch the product details by ID
export const fetchProductDetailsById = createAsyncThunk(
  "productsAssociated/fetchProductDetailsById",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/product/${productId}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      // console.log("fetchProductDetailsById==>", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Parse the input date string
  const inputDate = new Date(dateString);

  // Assuming dateString is in UTC, get the UTC offset in minutes
   const utcOffsetMinutes =Math.abs(inputDate.getTimezoneOffset());;

  // Convert the UTC offset to hours
  const utcOffsetHours = utcOffsetMinutes / 60;



  // Adjust the hour based on the UTC offset
  const adjustedHour = inputDate.getUTCHours() + utcOffsetHours ;

  // Set the adjusted hour in the input date
  inputDate.setUTCHours(adjustedHour);

  const formattedDate = inputDate.toLocaleString("en-US", options);

  // Replace the slash (/) with a hyphen (-) and remove commas
  const withoutComma = formattedDate.replace(/[\/]/g, "-");

  return withoutComma.replace(/[\,]/g, " ");
}


// Step 3: Create an action to fetch associated product details
// Define an async thunk to fetch associated product details
export const fetchAssociatedProductDetails = createAsyncThunk(
  "productsAssociated/fetchAssociatedProductDetails",
  async (token, { dispatch, getState }) => {
    const resultAction = await dispatch(fetchAssociatedProductIds(token));
    const associatedProductIds = resultAction.payload;

    // Prepare an array to hold product details
    const productDetails = [];

    // Fetch product details for each associated product
    for (const item of associatedProductIds) {
      const existingProduct = getState().productsAssociated.productDetails.find(
        (product) => product.id === item.product_id
      );

      if (!existingProduct) {
        // If it doesn't exist, fetch the product details
        const response = await axios.get(
          `${BASE_URL}/product/${item.product_id}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        productDetails.push({
          ...response.data,
          balance: item.balance,
          active_since:formatDate(item.active_since)
        });

      } else {
        // If it exists, add it to productDetails without making an API request
        productDetails.push({
          ...existingProduct,
          balance: item.balance,
          active_since: formatDate(item.active_since)
        });
      }
    }

    return productDetails;
  }
);


export const fetchApiKey = createAsyncThunk(
  "productsAssociated/fetchApiKey",
  async (productId, { getState }) => {
    try {
      const state = getState();
      const token = state.auth.token; // Replace with your actual authentication slice

      const response = await axios.get(
        `${BASE_URL}/associate/retrieve?product_id=${productId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
        // console.log(response, token);
      if (response.data) {

        return response.data.api_key;
      } else {
        // Handle the case when API key is not available
        toast.error("API Key not available. Please generate one.");
        return // rejectWithValue("API Key not available");
      }
    } catch (error) {

      toast.error(error.response.data.detail);
       return // rejectWithValue(error.response.data);
    }
  }
);

// Step 4: Define the product slice
const productsAssociated = createSlice({
  name: "productsAssociated",
  initialState: {
    associatedProductIds: [],
    productDetails: [], // Added this array to store product details
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssociatedProductIds.fulfilled, (state, action) => {
        return {
          ...state,
          associatedProductIds: action.payload,
        };
      })
      .addCase(fetchProductDetailsById.fulfilled, (state, action) => {
        // Check if a product with the same ID already exists
        const existingProduct = state.productDetails.find(
          (product) => product.id === action.payload.id
        );

        if (existingProduct) {
          // Update the existing product
          Object.assign(existingProduct, action.payload);
        } else {
          // Push a new product into the array
          state.productDetails.push(action.payload);
        }
      })
      .addCase(fetchAssociatedProductIds.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(fetchAssociatedProductDetails.fulfilled, (state, action) => {
        // Replace the product details with the ones received from the async thunk
        state.productDetails = action.payload;
      })
      .addCase(fetchApiKey.pending, (state)=>{
        state.status = 'Loading'
      })
      .addCase(fetchApiKey.fulfilled, (state, action) => {
        // Find the product by ID
        const productId = action.meta.arg; // Assuming the product ID is passed as an argument to the async thunk
        const productIndex = state.productDetails.findIndex(
          (product) => product.id === productId
        );

        if (productIndex !== -1) {
          // If the product is found, update its API key

          state.productDetails[productIndex].apiKey = action.payload;
        }
      })
      .addCase(fetchApiKey.rejected, (state, action) => {
        // Handle API key fetch failure if needed
      });
  },


});

// Export selectProductDetails
export const selectProductDetails = (state) =>
  state.productsAssociated.productDetails;
export const selectAssociatedProductIds = (state) =>
  state.productsAssociated.associatedProductIds;

export default productsAssociated.reducer;
