// EmailVerification.jsx
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink,  useNavigate } from "react-router-dom";

import Abstract from "../components/abstract/Abstract";
import doneIcon from "./../img/done.svg";
import { PuffLoader } from "react-spinners";
import AuthHeader from "../components/auth/AuthHeader";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  generateVerificationCode,
  selectCurrentTokenSaver,
  setToken,
} from "../redux/reducers/auth/userSlice";
// const BASE_URL = "https://api.dev.siinlab.com";


const isProduction = window.location.hostname === 'platform.siinlab.com';
const BASE_URL = isProduction ? "https://api.siinlab.com/v1" : "https://api.dev.siinlab.com/v1"

const EmailVerification = ({ email, setHideComponents }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formStep, setFormStep] = useState(3);
  const [verificationCode, setVerificationCode] = useState("");
  const tokenSaver = useSelector(selectCurrentTokenSaver) || localStorage.getItem('tokenSaver');





  const verifyEmail = async () => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/user/verify-email?code=${verificationCode}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${tokenSaver}`,
          },
        }
      );
      if (response.status === 200) {

        toast.success(response.data.message);
        setFormStep(4);
        setTimeout(() => {

          navigate("/");
        }, 5000);
        dispatch(setToken(tokenSaver));
        localStorage.setItem('token',tokenSaver)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.detail);
    }
  };
  const initialized = useRef(false);
  useEffect(() => {


    if (!initialized.current) {
      initialized.current = true;

      dispatch(generateVerificationCode(tokenSaver));
    }
  }, [dispatch, tokenSaver]);


  return (

    <section className="flex sign">

      <section className="sign-form">
        {/* Common header for all steps */}
        <div className="flex-col">
          <AuthHeader />
          {formStep === 3 && (
            <>
              <ul className="header-list flex align-items-start">
                <li className="header-list-item mx-2 flex-col text-center">
                  <span
                    className={"user_sing mb-1 circle fill flex bg-success"}
                  >
                    {" "}
                    <img src={doneIcon} width={"15px"} alt="doneIcon" />{" "}
                  </span>
                  <small>Credentials</small>
                </li>
                <div className="bar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130px"
                    height="7"
                    viewBox="0 0 404 7"
                    fill="none"
                  >
                    <path
                      d="M0.464844 3.5C0.464844 1.567 2.03185 0 3.96484 0H399.965C401.898 0 403.465 1.567 403.465 3.5C403.465 5.433 401.898 7 399.965 7H3.96485C2.03185 7 0.464844 5.433 0.464844 3.5Z"
                      fill="url(#paint0_linear_315_593)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_315_593"
                        x1="43.1523"
                        y1="3.99988"
                        x2="202.152"
                        y2="3.99988"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#499173" />
                        {/* <stop offset="1" stopColor="#BBBBBB"/> */}
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <li className="header-list-item mx-2 flex-col text-center">
                  <span
                    className={"user_sing mb-1 circle fill flex bg-success"}
                  >
                    {" "}
                    <img src={doneIcon} width={"15px"} alt="doneIcon" />{" "}
                  </span>
                  <small>Company information</small>
                </li>
                <div className="bar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130px"
                    height="7"
                    viewBox="0 0 404 7"
                    fill="none"
                  >
                    <path
                      d="M0.464844 3.5C0.464844 1.567 2.03185 0 3.96484 0H399.965C401.898 0 403.465 1.567 403.465 3.5C403.465 5.433 401.898 7 399.965 7H3.96485C2.03185 7 0.464844 5.433 0.464844 3.5Z"
                      fill="url(#paint0_linear_345_593)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_345_593"
                        x1="43.1523"
                        y1="3.99988"
                        x2="202.152"
                        y2="3.99988"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#499173" />
                        <stop offset="0.940366" stopColor="#1A5382" />
                        {/* <stop offset="1" stopColor="#BBBBBB"/> */}
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <li className="header-list-item mx-2 flex-col text-center">
                  <span className={"user_sing mb-1 circle flex text-white"}>
                    3
                  </span>
                  <small> E-mail verification</small>
                </li>
              </ul>

              {/* Content based on formStep */}

              <div className="flex-col login-form">
                <h6 className="title-thid-step">Almost there!</h6>
                {/* Include content for step 3 here */}
                <p className="text-center">
                  We’ve sent an e-mail verification link to your <b>{email}</b>{" "}
                  inbox, click on it to verify the ownership of your email
                </p>
                <p className="py-3 text-center">
                  Didn’t receive it? check your spam folder
                </p>
                <Link className="resend-verification" onClick={()=>dispatch(generateVerificationCode(tokenSaver))}>
                  Re-send verification email
                </Link>
                <input
                  type="text"
                  className="inputs-sign p-1 px-3 my-3"
                  placeholder=" verification code"
                  value={verificationCode}

                  onChange={(e) => setVerificationCode(e.target.value)}
                />

                <button
                onClick={verifyEmail}
                className="sign-btn text-center"
                disabled={verificationCode.length < 6}>

                  Next
                </button>
                <NavLink
                  className={"sign-btn signup-btn text-center"}
                  to={"/user/login"}
                >
                  Back to login
                </NavLink>
              </div>
            </>
          )}

          {formStep === 4 && (
            <>
              <ul className="header-list flex align-items-start">
                <li className="header-list-item mx-2 flex-col text-center">
                  <span
                    className={"user_sing mb-1 circle fill flex bg-success"}
                  >
                    {" "}
                    <img src={doneIcon} width={"15px"} alt="doneIcon" />{" "}
                  </span>
                  <small>Credentials</small>
                </li>
                <div className="bar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130px"
                    height="7"
                    viewBox="0 0 404 7"
                    fill="none"
                  >
                    <path
                      d="M0.464844 3.5C0.464844 1.567 2.03185 0 3.96484 0H399.965C401.898 0 403.465 1.567 403.465 3.5C403.465 5.433 401.898 7 399.965 7H3.96485C2.03185 7 0.464844 5.433 0.464844 3.5Z"
                      fill="url(#paint0_linear_315_593)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_315_593"
                        x1="43.1523"
                        y1="3.99988"
                        x2="202.152"
                        y2="3.99988"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#499173" />
                        {/* <stop offset="1" stopColor="#BBBBBB"/> */}
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <li className="header-list-item mx-2 flex-col text-center">
                  <span
                    className={"user_sing mb-1 circle fill flex bg-success"}
                  >
                    {" "}
                    <img src={doneIcon} width={"15px"} alt="doneIcon" />{" "}
                  </span>
                  <small>Company information</small>
                </li>
                <div className="bar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130px"
                    height="7"
                    viewBox="0 0 404 7"
                    fill="none"
                  >
                    <path
                      d="M0.464844 3.5C0.464844 1.567 2.03185 0 3.96484 0H399.965C401.898 0 403.465 1.567 403.465 3.5C403.465 5.433 401.898 7 399.965 7H3.96485C2.03185 7 0.464844 5.433 0.464844 3.5Z"
                      fill="url(#paint0_linear_315_593)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_315_593"
                        x1="43.1523"
                        y1="3.99988"
                        x2="202.152"
                        y2="3.99988"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#499173" />
                        {/* <stop offset="1" stopColor="#BBBBBB"/> */}
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <li className="header-list-item mx-2 flex-col text-center">
                  <span
                    className={"user_sing mb-1 circle fill flex bg-success"}
                  >
                    {" "}
                    <img src={doneIcon} width={"15px"} alt="doneIcon" />{" "}
                  </span>
                  <small> E-mail verification</small>
                </li>
              </ul>
              <div className="flex-col login-form">
                <h6 className="title-last-step">
                  Your account is being created
                </h6>
                <div className="flex moonloader">
                  <PuffLoader color="#1A5382" size={60} />
                </div>
                <small className="small-last-step text-center">
                  Please wait, you will automatically be redirected to your
                  dashboard in a few moments
                </small>
              </div>
            </>
          )}
        </div>
      </section>
      <Abstract />
    </section>
  );
};

export default EmailVerification;
