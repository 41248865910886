// index.js
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import MoonLoader from "react-spinners/BeatLoader";
import {  HelmetProvider } from "react-helmet-async";
// toast.configure()
const loadingMarkup = (
  <div className="flex moonloader">
    <MoonLoader color="#607D8B" size={20} />
  </div>
);
const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById("root"));
// const root = document.getElementById("root");
// react-nap: https://stackoverflow.com/questions/73103876/react-snap-is-working-perfect-when-running-in-localhost-but-in-my-remote-server
// if (root.hasChildNodes()) {
//   hydrate(
// <React.StrictMode>
//     <I18nextProvider i18n={i18n}>
//       <Suspense fallback={loadingMarkup}>
//         <ToastContainer theme="colored" position="top-center"></ToastContainer>
//         <BrowserRouter>
//           <Provider store={store}>
//             <HelmetProvider context={helmetContext}>


//             <Routes>
//               <Route path="/*" element={<App />} />
//             </Routes>
//             </HelmetProvider>
//           </Provider>
//         </BrowserRouter>
//       </Suspense>
//     </I18nextProvider>
//   </React.StrictMode>
//     , root);
// } else {
//   render(
// <React.StrictMode>
//     <I18nextProvider i18n={i18n}>
//       <Suspense fallback={loadingMarkup}>
//         <ToastContainer theme="colored" position="top-center"></ToastContainer>
//         <BrowserRouter>
//           <Provider store={store}>
//             <HelmetProvider context={helmetContext}>


//             <Routes>
//               <Route path="/*" element={<App />} />
//             </Routes>
//             </HelmetProvider>
//           </Provider>
//         </BrowserRouter>
//       </Suspense>
//     </I18nextProvider>
//   </React.StrictMode>
//     , root);
// }
// ####
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={loadingMarkup}>
        <ToastContainer theme="colored" position="top-center"></ToastContainer>
        <BrowserRouter>
          <Provider store={store}>
            <HelmetProvider context={helmetContext}>


            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
            </HelmetProvider>
          </Provider>
        </BrowserRouter>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
