import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  selectCurrentToken,
  selectCurrentUser,
} from "../../redux/reducers/auth/userSlice";
import rightArrow from "../../img/rightArrow.svg";
import leftArrow from "../../img/leftArrow.svg";
import imgExmple from "../../img/product-icon.svg";
import profilePerson from "../../img/icon_person_.svg";
import line6 from "../../img/Line-6.svg";
import line7 from "../../img/Line7.png";
import { toast } from "react-toastify";
import axios from "axios";
import { fetchAssociatedProductDetails } from "../../redux/reducers/products/productsAssociatedSlice";
import JoyRide from "react-joyride";
import Tour from "../onboarding/Tour";
// import { Helmet } from "react-helmet";


// const BASE_URL = "https://api.dev.siinlab.com";
const isProduction = window.location.hostname === "platform.siinlab.com";
const BASE_URL = isProduction
  ? "https://api.siinlab.com/v1"
  : "https://api.dev.siinlab.com/v1";

function PopupBalance({ product, curentUser }) {
  const modalElements = document.getElementsByClassName('modal_pop_up');


const isModalVisible = Array.from(modalElements).some(element => element.classList.contains('show'));
console.log(isModalVisible,  modalElements );
// const currentPage = isModalVisible ? "pup_up_balance" : "";

  const associatedProductDetails = useSelector(
    (state) => state.productsAssociated.productDetails
  );
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  const currentProduct = associatedProductDetails.filter(
    (prod) => prod.id === product.id
  )[0];
// console.log(currentProduct);
  // console.log("currentProduct==>", currentProduct);
  const [balanceChange, setBalanceChange] = useState("$ 0.00");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [toastImage, setToastImage] = useState(null);

  const showToast = (message, imageSource, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastImage(imageSource);


    setTimeout(() => {
      setToastMessage("");
      setToastType("");
      setToastImage(null);
    }, 5000); // Adjust the delay (in milliseconds) based on your preference
    // }
  };

  // const [currentBalance, setCurrentBalance] = useState(product.balance);
  useEffect(() => {
    setToastMessage("");

    setToastType("");
  }, [product]);

  const handleBalanceChange = (value) => {
    setBalanceChange(value);
  };

  const handleAddFunds = async () => {
    if (balanceChange <= 0 || isNaN(balanceChange)) {
      toast.error("Please enter a valid amount to add funds.");
      return;
    }

    try {
      // Display loading message before making the API call
      showToast("Transferring...", line6, "waitng");

      const response = await axios.patch(
        `${BASE_URL}/associate/top-up?product_id=${product.id}&balance=${balanceChange}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Display the success message
      toast.success("Funds added successfully!");
      // Reset the balance change input
      setBalanceChange("$ 0.00");
      // Refresh associated product details
      dispatch(fetchAssociatedProductDetails(token));
      // Assuming this action fetches the current user details
      dispatch(fetchUser(token));

      return;
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.detail);
      showToast("Fund transfer failed " + error.response.data.detail, "danger");
    }
  };

  const handleRestoreFunds = async () => {
    if (balanceChange <= 0 || isNaN(balanceChange)) {
      toast.error("Please enter a valid amount to restore funds.");
      return;
    }

    try {
      showToast("Transfering...", line7, "waiting");

      const response = await axios.patch(
        `${BASE_URL}/associate/lower-balance?product_id=${product.id}&balance=${balanceChange}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Funds restored successfully! ✔");
      // showToast("Funds restored successfully! ✔", "success");
      setBalanceChange("$ 0.00"); // Reset the balance change input
      dispatch(fetchAssociatedProductDetails(token)); // Refresh associated product details
      dispatch(fetchUser(token)); // Assuming this action fetches the current user details
    } catch (error) {
      toast.error(error.response.data.detail);
      // showToast("Fund transfer failed " + error.response.data.detail, "danger");
    }
  };

  const handleEmptyFunds = async (balance) => {


    try {
      showToast("Transfering...", line7, "waiting");
      const response = await axios.patch(
        `${BASE_URL}/associate/lower-balance?product_id=${product.id}&balance=${balance}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Balance Empty successfully! ✔");
      setBalanceChange("$ 0.00"); // Reset the balance change input
      dispatch(fetchAssociatedProductDetails(token)); // Refresh associated product details
      dispatch(fetchUser(token));
    } catch (error) {
      toast.error(error.response.data.detail);
      showToast("Fund transfer failed " + error.response.data.detail, "danger");
    }
  };
  return (
    <>
      <div className="popup_balance_container">
        {/* <!-- Modal --> */}
        <Tour  currentPage={"pup_up_balance"} />
        <div
          className="modal_pop_up modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header modalheader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close-modal" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body flex-space-bet">

                <div className="flex-col">
                  <div className="profile_person">
                    {" "}
                    <img src={profilePerson} alt="" />
                  </div>
                  <p className="text-center"> Global Balance</p>{" "}
                  <p> ${curentUser.balance}</p>
                </div>
                <div className="flex-col ">
                  <div className="flex-col" id="arrows">
                    {/* <span>{toastType && toastMessage  }</span> */}
                    {toastType ? (
                      <div className="flex-col">
                        {toastType && toastMessage}
                        {toastImage && (
                          <img
                            src={toastImage}
                            width={"100px"}
                            alt="toastImage"
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <img src={rightArrow} width={"25px"} alt="rightArrow" />
                        <img src={leftArrow} width={"25px"} alt="leftArrow" />
                      </>
                    )}
                  </div>
                  <input
                    className="input-balance"
                    // data-product-id={product.id}
                    type="number"
                    // value={increaseBalances[product.id] || 0}
                    placeholder="$ 0.00"
                    required
                    value={balanceChange}
                    onChange={(e) =>
                      handleBalanceChange(Number(e.target.value))
                    }
                  />
                </div>
                <div className="flex-col">
                  <img src={currentProduct?.icon} width={"50px"} alt="" />
                  <p className="text-center">{currentProduct?.name} </p>
                  <p className="text-center"> ${currentProduct?.balance}</p>
                </div>
              </div>
                <div className="modal_footer flex">
                  <button
                  id="step7"
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleAddFunds}
                  >
                    Add funds
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleRestoreFunds}
                  >
                    Restore funds
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleEmptyFunds(currentProduct?.balance)}
                  >
                    Empty balance
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupBalance;
