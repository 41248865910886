import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../img/logo-siin.png'

function AuthHeader() {
  return (
    <header>
    <div className='logo'>
    <NavLink to="/" className="header__content__logo">
          <img src={logo} width={"150px"} alt="Logo"  />
        </NavLink>
        </div>
    {/* <ul className='header-list flex'>
     <li className='header-list-item' >
     <NavLink className={'user_sing '} to={"/user/register"}>Signup</NavLink>
     </li>
     <li className='header-list-item'>
     <NavLink className={'user_sing '} to={"/user/login"}>Login</NavLink>
     </li>
    </ul> */}
    </header>
  )
}

export default AuthHeader

