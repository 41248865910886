import React, { useEffect, useRef, useState } from "react";
import "./styles/profile.css";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
import {
  fetchUser,
  loading,
  selectCurrentToken,
  selectCurrentUser,
} from "../redux/reducers/auth/userSlice";
import axios from "axios";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/BeatLoader";
import logoBalance from "../img/logo2.png";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import payplChIco from "../img/paypl-chk.png";
import payplVisaIco from "../img/visa-pay.png";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const status = useSelector(loading);

  // const BASE_URL = "https://api.dev.siinlab.com"
  const isProduction = window.location.hostname === "platform.siinlab.com";
  const BASE_URL = isProduction
    ? "https://api.siinlab.com"
    : "https://api.dev.siinlab.com/v1";

  let [shouldRenderPayPalButtons, setShouldRenderPayPalButtons] =
    useState(false);
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchUser(token));
  }, [dispatch, token]);

  const currentUser = useSelector(selectCurrentUser);

  // TOP UP THE BALANCE
  const [balance, setBalance] = useState("");
  // check if the given balce is number
  const handleBalanceChange = (event) => {
    const enteredBalance = event.target.value;

    // Check if the entered balance is a valid number
    if (isNaN(enteredBalance)) {
      toast.error("Please enter a valid number for the balance.");
      return;
    }

    setBalance(enteredBalance);
  };

  // ADD BALANCE
  const addBalance = async () => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/user/top-up?balance=${balance}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      // console.log("responseeeeeeeee", response);
      toast.success("Your balance was added successfully!");
      setBalance("");
      await dispatch(fetchUser(token));
      return data;
    } catch (error) {
      toast.error(error.response.data.detail);
      console.log("errorrrr", error.response.data.detail);
    }
  };

  // CHANGE PASSWORD
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  

  // Start of Paypal Integration written by Reda
  const createOrder = (data, actions) => {
    // Set up the transaction details, such as amount and currency
    // Get the current value of balance using the local variable
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: balance, // Set your desired top-up amount here
          },
        },
      ],
    });
  };

  const myebugger = () => {
    if (balance <= 0) {
      toast.error("The balance should be greater than 0");
      // toast.info
    }
    setShouldRenderPayPalButtons(true);
    // console.log(setShouldRenderPayPalButtons);
    console.log(Number(balance));
  };

  if (paidFor) {
    toast.success("Thank you for you puchase");
  }

  if (error) {
    // display error message on a modal or redirect the user to the error page
    toast.error(error);
  }

  const onApprove = async (data, actions) => {
    try {
      // Capture the funds when the user approves the payment
      const order = await actions.order.capture();
      // If capturing funds is successful, call addBalance
      if (order.status === "COMPLETED") {
        await addBalance();
        toast.success("Your payment was processed successfully!");
        const bodyElement = document.body;
        const modalBackdropElements =
          document.getElementsByClassName("modal-backdrop");

        // Remove classes from body
        bodyElement.classList.remove("modal-open");
        // Remove classes from each modal backdrop element
        for (let i = 0; i < modalBackdropElements.length; i++) {
          modalBackdropElements[i].classList.remove(
            "modal-backdrop",
            "fade",
            "show"
          );
        }
      } else {
        setError("Error capturing funds. Please try again.");
      }
      console.log("Captured order:", order);
      // hndleApprove(data.oderID);
      // return actions.order.capture().then(function (details) {
      //   // Handle the successful payment, e.g., show a success message to the user
      //   alert("Transaction completed by " + details.payer.name.given_name);
      // });
    } catch (error) {
      toast.error("Error processing payment. Please try again.");
      console.error("Error capturing funds:", error);
    }
  };

  const onError = (err) => {
    setError(err);
    toast.error(error);
  };

  const onCancel = () => {
    toast.info(
      "You have cancelled the payment process. If you have any questions or concerns, please contact our support team."
    );
  };

  // End of Paypal Integration written by Reda

  return (


    // Payment
    <section className="payment container">
      <h2 className="title title-payment">{t("profile.title")}</h2>
      <div className="content flex-space-bet">
        <div className="global-balance flex-col">
          <img src={logoBalance} alt="" />
          <p className="text-center">{t("profile.globalBalance")}</p>{" "}
          <p> ${currentUser?.balance}</p>
        </div>
        <div className="payment-container flex-space-bet">
          <div className="flex-col">
            <p>{t("profile.addFunds")}</p>
            <span>
              {t("profile.amount")}: <br />
              <input
                className="balance-input "
                type="number"
                placeholder="$ 0.00"
                value={balance}
                onChange={handleBalanceChange}
                required
              />
              <br />

            </span>
          </div>
          <div className="flex-col">
            <img src={payplVisaIco} width={"150px"} alt="" />
            <div className="flex">
              <img
                onClick={myebugger}
                className="payplChIco"
                src={payplChIco}
                width={"120px"}
                alt=""
                // type="button"
                data-toggle="modal"
                data-target="#exampleModal"
              />
            </div>
          </div>
        </div>
        {/* Paypal Integration by Reda */}
        {/* <td> */}
        {balance > 0 && (
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header modal-header-payment">
                  <h6
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    {t("profile.addFunds")}
                  </h6>
                  {/* <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  {/* <Checkout/> */}
                  {shouldRenderPayPalButtons && (
                    <PayPalScriptProvider
                      options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                      }}
                    >
                      <PayPalButtons
                        style={{
                          layout: "vertical",
                          color: "gold",
                          shape: "pill",
                          label: "paypal",
                          height: 35,
                        }}
                        onClick={(data, actions) => {
                          return actions.resolve();
                        }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        onCancel={onCancel}
                      />
                    </PayPalScriptProvider>
                  )}
                </div>
                <div className="modal-footer flex">
                  <button
                    type="button"
                    className="btn btn-danger px-3"
                    data-dismiss="modal"
                  >
                    {t("profile.closeModal")}
                  </button>
                  {/* <button type="button" className="btn btn-primary">
                  {t('profile.closeModal')}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Profile;
