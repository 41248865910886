//Tour.js
import React, { useEffect, useState } from "react";
import JoyRide from "react-joyride";

// Tour steps
const TOUR_STEPS = [


 {
   "target": "#step3",
   "disableBeacon": true,
   "content": "By clicking on the 'Activate' button, you will add this product to your active products ",
   placement:"top"
 },
 // {
 //   "target": "#step4",
 //   "content": "This is your active products page where you can find all the products you've enabled.",
 //   placement:"right"
 // },
 // {
 //   "target": "#step5",
 //   "content": "This is the product balance",
 //   placement:"right"
 // },
 // {
 //   "target": "#step6",
 //   "content": "To utilize this product, simply boost your balance by clicking on the 'Balance' button",
 //   placement:"right"
 // },
 // {
 //   "target": "#step7",
 //   "content": "You can pop up the balance of this product by Entering the price you want to add and clicking the Add Funds button",
 //   placement:"top"
 // },
];

const styles = {
  tooltipContainer: {
    textAlign: "left",
  },
  buttonNext: {
    backgroundColor: "green",
  },
  buttonBack: {
    marginRight: 10,
  },
};
// Tour component
const TourAllProducts = () => {
  const [runTour, setRunTour] = useState(false);
  useEffect(() => {

    const isFirstVisiteAllProduct = localStorage.getItem("isFirstVisiteAllProduct");
    if (!isFirstVisiteAllProduct ) {
     setRunTour(true);
      localStorage.setItem("isFirstVisiteAllProduct", true);
    }

  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished") {
      // Optionally, you can perform actions when the tour is finished
      console.log('finished onboarding');
    }
  };
  return (
    <>
      <JoyRide
        steps={TOUR_STEPS}
        // continuous={true}
        styles={styles}
        run={runTour}
        autoStart={true}
        disableBeacon= {true}
        callback={()=>{}}
        hideBackButton
        scrollToFirstStep
        showSkipButton={true}
        showProgress
      />
    </>
  );
};
export default TourAllProducts;
