import React, { useEffect, useState } from "react";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import ResetPassword from "./components/auth/styles/ResetPassword";
import AllProducts from "./pages/AllProducts";
import ActiveProducts from "./pages/ActiveProducts";
import Profile from "./pages/Profile";
import Missing from "./pages/Missing";
import RequireAuth from "./pages/RequireAuth";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import EmailVerification from "./pages/EmailVerification";
import Payment from "./pages/payment";
import SEO from "./SEO";
import { Helmet } from "react-helmet-async";
import observeBodyChanges from './components/utils/bodyObserver';

import adjustPaddingRight from './components/utils/observeModalEvents'

function App() {
  const [hideComponents, setHideComponents] = useState(false);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    observeBodyChanges();
    adjustPaddingRight();
  }, []);

  return (
    <>
      {/* <Helmet>
        <title> Platform - Siin Lab</title>
        <meta
          name="description"
          content="A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once."

        />

      </Helmet> */}
      {/* <SEO
     title='Platform - Siin Lab'
      description='A barcode scanner capable of detecting and recognizing multiple barcodes in an image at once.'
      pageLink="https://platform.dev.siinlab.com"
      type='website'
      name='platform.siinlab.com'
      keywords='Moroccan ANPR, Barcode Model, Facial Recognition,'
     /> */}
      <Routes>
        <Route
          path="/"
          element={<RootLayout hideComponents={hideComponents} />}
        >
          {/*  // Public Routes*/}

          <Route
            path="user/login"
            element={token ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="user/register"
            element={
              token ? (
                <Navigate to="/" />
              ) : (
                <Signup setHideComponents={setHideComponents} />
              )
            }
          />
          <Route
            path="user/verify_email"
            element={
              <EmailVerification setHideComponents={setHideComponents} />
            }
          />

          {/* // private routes */}
          <Route element={<RequireAuth />}>
            <Route path="all_products" element={<AllProducts />} />

            <Route path="/" element={<ActiveProducts />} />
            {/* <Route path="profile" element={<Profile />} /> */}
            <Route path="active_products" element={<ActiveProducts />} />
            <Route path="user/reset_pass" element={<ResetPassword />} />
            <Route path="payment" element={<Payment />} />
          </Route>
          {/* <Route path="user/verify_email" element={<EmailVerification />} /> */}

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
