import React, { useEffect, useState } from "react";
import JoyRide from "react-joyride";

// Tour steps for All Products
const TOUR_ALL_PRODUCTS_STEPS = [
  {
    target: "#step3",
    disableBeacon: true,
    content:
      "Click the 'Activate' button to add this product to your active products. 🚀",
    placement: "bottom",
  },
  {
    target: "#step4",
    disableBeacon: true,
    content:
      "This is your Active Products page, where you can find all the products you've enabled. 😎",
    placement: "right",
  },
  // Add more steps as needed
];

const TOUR_ACTIVE_PRODUCTS_STEPS = [
  {
    target: "#step5",
    disableBeacon: true,
    content: "This represents the product balance. 💰",
    placement: "right",
  },
  {
    target: "#step6",
    content:
      "To use this product, boost your balance by clicking the 'Balance' button. ⚖️",
    placement: "left",
    disableBeacon: true,
  },
  {
    target: "#step8",
    content: "To use this product, click on the 'Manage' button. 👩‍💼",
    placement: "left",
    disableBeacon: true,
  },
  // Add more steps as needed
];

const TOUR_MODAL_STEPS = [
  {
    target: "#step7",
    content:
      "Pop up the balance of this product by entering the desired amount and clicking the 'Add Funds' button. 💸",
    placement: "left",
    disableBeacon: true,
  },
];


// Tour steps for the general tour
const TOUR_GENERAL_STEPS = [
  {
    target: "#step1",
    content:
      "As a welcome gift, you've received $10 in your account. Start exploring and enjoy your experience! 🎉",
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: "#step2",
    content:
      "To get started, click on this icon where you find 'All Products' and select a product you'd like to use. Happy exploring! 🌟",
    placement: "right",
    disableBeacon: true,
  },
];


const styles = {
  tooltipContainer: {
    textAlign: "left",
  },
  buttonNext: {
    backgroundColor: "green",
  },
  buttonBack: {
    marginRight: 0,
  },


    buttonClose: {
      // backgroundColor: 'green',
      // borderRadius: '50px',
      // padding:'5px',
      color: "red"
    }
};

// Tour component
const Tour = ({ currentPage }) => {
  const [run, setRun] = useState(false);


  useEffect(() => {
    // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const checkAndStartTour = async () => {
      // Modal Pop_Up balance
      const parentElementPopUP = document.querySelector(".popup_balance_container");
      const modalElementPopUP = parentElementPopUP?.querySelector('.modal_pop_up');
      const isModalPopUpVisible =
        modalElementPopUP && modalElementPopUP.classList.contains("show");

      if (
        !localStorage.getItem("isPopUpBlance")&&
        currentPage === "pup_up_balance" &&
        isModalPopUpVisible

      ) {
        // await delay(1500);
        setRun(true);
        localStorage.setItem("isPopUpBlance", true);
      }

      // Modal Manage Balance
      const modalElementManage = document.querySelector(".manage_balance");
      const isModalBalanceVisible =
        modalElementManage && modalElementManage.classList.contains("show");

      if (
        !localStorage.getItem("isManageBalance") &&
        currentPage === "manage_balance" &&
        isModalBalanceVisible

      ) {
        // await delay(1500);
        setRun(true);
        localStorage.setItem("isManageBalance", true);
      }


      const isFirstVisitActiveProduct = localStorage.getItem("isFirstVisitActiveProduct");
      const isFirstVisiteAllProduct = localStorage.getItem("isFirstVisiteAllProduct");
      const isSecondVisiteAllProduct = localStorage.getItem("isSecondVisiteAllProduct");

      if (!isFirstVisiteAllProduct && currentPage === "all_products") {
        // await delay(500);
        setRun(true);
        localStorage.setItem("isFirstVisiteAllProduct", true);
      }

      if (currentPage === "active_products" && !isSecondVisiteAllProduct) {
        setRun(true);
        localStorage.setItem("isSecondVisiteAllProduct", true);
      }

      if (!isFirstVisitActiveProduct && currentPage === "home_page") {
        setRun(true);
        localStorage.setItem("isFirstVisitActiveProduct", true);
      }
    };

    setTimeout(() => {
      checkAndStartTour();
    }, 500);
  }, [currentPage]);




  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === "finished") {
      // Optionally, you can perform actions when the tour is finished
    }
  };

  const tourSteps =
    currentPage === "all_products"
      ? TOUR_ALL_PRODUCTS_STEPS
      : currentPage === "active_products"
      ? TOUR_ACTIVE_PRODUCTS_STEPS
      : currentPage === "pup_up_balance"
      ? TOUR_MODAL_STEPS
      : currentPage === "home_page"
      ? TOUR_GENERAL_STEPS
      : [];

  return (
    <>
      <JoyRide
        steps={tourSteps}
        styles={styles}
        run={run}
        callback={handleJoyrideCallback}
        hideBackButton
        scrollToFirstStep
        // showSkipButton={true}
        showProgress
      />
    </>
  );
};

export default Tour;
