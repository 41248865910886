import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

function ResetPassword() {
  const [email, setEmail] = useState('')
  const hanldeResetPwd = async()=>{
    try {
      const response =await axios.post(`https://dev.siinlab.com/user/reset?email=${email}`,{},
      {
        headers:{
          "Accept": "application/json"
        }
      })
      const data = response.data
      if (data.status_code ===500) {
        toast.error(data.detail)
      }
      console.log(response.data);
    } catch (error) {
      toast.error(error.message)
      console.log(error.message);
    }
  }
  return (
    <section className="verify-email-form">
   <h1 className="form-title">Reset Password</h1>
   <form >
     <label htmlFor="email">Email:</label>
     <input type="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)}  />
     <button className='btn' type='button' onClick={hanldeResetPwd}>Verify</button>

   </form>
   {/* {verificationResult && <p>{verificationResult}</p>} */}
 </section>
  )
}

export default ResetPassword

